import React from 'react'
import { Message } from 'semantic-ui-react'

const NoResult: React.FC<{ name: string }> = ({ name }) => {
    return (
        <div style={{ marginTop: 20, marginBottom: 20 }}>
            <Message
                error
                list={[
                    "Aucune " + name + " pour le moment",
                ]}
            />
        </div>
    )
}

export default NoResult
