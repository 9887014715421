import React, { Fragment, useState, useEffect } from 'react';
import { Header, Grid, Button, Image } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import PhotoWidgetDropzone from './PhotoWidgetDropzone';

interface IProps {
    loading: boolean;
    uploadPhotos: (photo: any[]) => void;
}
const PhotosUpload: React.FC<IProps> = ({ loading, uploadPhotos }) => {

    const [files, setFiles] = useState<any[]>([]);

    const deleteFile = (id: number) => {
        setFiles((prevFiles) => prevFiles.filter((_item, index) => index != id));
    };
    useEffect(() => {
        return () => {
            files.forEach(file => URL.revokeObjectURL(file.preview));
        };
    });

    return (
        <Fragment>
            <br />
            <Grid>

                <Grid.Column width={16}>
                    <Header color='teal' sub content='Ajouter les photos' />
                    <PhotoWidgetDropzone setFiles={setFiles} />
                </Grid.Column>
                <Grid.Column width={16}>
                    <Grid style={{ marginBottom: 20 }}>
                        {files.length > 0 &&
                            files.map((file, index) => (
                                <Fragment>
                                    <div className='img-block-preview' style={{ height: 230 }}>
                                        <button className='btn-delete-file' onClick={() => deleteFile(index)}><b>X</b></button>
                                        <div className='img-preview-Content' style={{ height: 200, width: 'auto', overflow: 'hidden', marginBottom: 10 }}>
                                            <Image style={{ maxHeight: 180, borderRadius: 3 }} src={file.preview!} />
                                        </div>
                                        <div style={{ textAlign: 'center', fontSize: 'medium', fontWeight: 'bold', width: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                                            <label >{file.name!}</label>
                                        </div>
                                    </div>

                                </Fragment>
                            ))
                        }
                    </Grid>
                </Grid.Column>
            </Grid>

            {files.length > 0 &&
                <Button.Group size="medium" floated="right" >
                    <Button positive icon='check' content="Confirmer" loading={loading} onClick={() => uploadPhotos(files!)} />
                <Button negative icon='close' content="Annuler" disabled={loading} onClick={() => setFiles([])} />
                </Button.Group>
            }
        </Fragment>
    );
};

export default observer(PhotosUpload);
