import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Icon, Header } from 'semantic-ui-react';

interface IProps {
    setFiles: (files: object[]) => void;
}

const dropzoneStyles = {
    border: 'dashed 3px',
    borderColor: '#eee',
    borderRadius: '5px',
    paddingTop: '30px',
    textAlign: 'center' as 'center',
    height: '200px'
};

const dropzoneActive = {
    borderColor: 'green'
};

const VideoWidgetDropzone: React.FC<IProps> = ({ setFiles }) => {
    const onDrop = useCallback(acceptedFiles => {
        setFiles(
            acceptedFiles.map((file: Blob) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })
            )
        );
    }, [setFiles]);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: ['.mp4','.mov','.avi','.flv']});

    return (
        <div
            {...getRootProps()}
            style={
                isDragActive ? { ...dropzoneStyles, ...dropzoneActive } : dropzoneStyles
            }
        >
            <input {...getInputProps()} />
            <Icon name='upload' size='huge' style={{ cursor: 'pointer' }} />
            <Header content='Charger votre vidéo ici' style={{ cursor: 'pointer' }} />
        </div>
    );
};

export default VideoWidgetDropzone;
