import { BaseStore } from "./baseStore";
import { observable, action, runInAction, reaction, toJS, computed } from 'mobx';
import agent from '../agent/agent';
import { toast } from "react-toastify";
import { history } from '../../index';
import { IFichier, IFranchiseur, IPhoto, IFranchiseurList, IFranchiseurIClientList, IFranchisor, ICompanyPack, ICompany } from "../models/IFranchiseur";
import { IAdresse } from "../models/IAdresse";
import { IPersonalPhoto } from "../models/IClient";

const LIMIT = 10;

export default class FranchiseurStore {
    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
        reaction(
            () => this.predicate.keys(),
            () => {
                this.page = 0;
                this.loadFranchiseurs();
            }
        )
        reaction(
            () => this.activeTab,
            activeTab => {
                if (activeTab != '')
                    return true;
            }
        )
    }
    @observable loadingPhotoAction = false;
    @observable loadingFranchideurs = false;
    @observable loadingFranchiseur = false;
    @observable exporting = false;
    @observable exportingAll = false;
    @observable submitting = false;
    @observable deletingFranchiseur = false;
    @observable loadingDownloadPack = false;
    @observable uploadingPhoto = false;
    @observable loadingPhotos = false;
    @observable franchiseur: IFranchiseur | null = null;
    @observable company: ICompany | null = null;
    @observable franchiseurs: IFranchiseur[] = [];
    @observable companyPhotos: IPersonalPhoto[] = [];
    @observable listPackAmount: ICompanyPack[] = [];
    @observable franchiseursCount = 0;
    @observable page = 0
    @observable predicate = new Map();
    @observable activeTab: string = "";
    @observable uploadingFile = false;
    @observable ITListFranchiseur: IFranchiseurList[] = [];
    @observable ITListFranchiseurs: IFranchiseurList[] = [];
    @observable ITListFranchiseursClients: IFranchiseurIClientList[] = [];
    @observable loadingInitial = false;


    @computed get axiosParams() {
        const params = new URLSearchParams();
        params.append('limit', String(LIMIT));
        params.append('offset', `${this.page ? this.page * LIMIT : 0}`);
        this.predicate.forEach((value, key) => {
            if (key === 'startDate') {
                params.append(key, value.toISOString())
            } else if (key === 'searchFranch') {
                var search = [...value][0];
                params.append(search[0], search[1])
            }
            else {
                params.append(key, value)
            }
        })
        return params;
    }

    @computed get totalPages() {
        return Math.ceil(this.franchiseursCount / LIMIT);
    }

    @action setInfo = (address: IAdresse, type: string) => {
        if (type == "Invoice") {
            this.franchiseur!.invoiceAddress2 = address.additionalAddress;
            this.franchiseur!.invoiceAddress1 = address.address;
            this.franchiseur!.invoiceCity = address.city;
            this.franchiseur!.invoiceCountry = address.country;
            this.franchiseur!.invoicePostalCode = address.postalCode;
            this.franchiseur!.invoiceCompany = address.companyName;
            this.franchiseur!.invoicePhone = address.phone;
        }
        else {
            this.franchiseur!.deliveryAddress2 = address.additionalAddress;
            this.franchiseur!.deliveryAddress1 = address.address;
            this.franchiseur!.deliveryCity = address.city;
            this.franchiseur!.deliveryCountry = address.country;
            this.franchiseur!.deliveryPostalCode = address.postalCode;
            this.franchiseur!.deliveryCompany = address.companyName;
            this.franchiseur!.deliveryPhone = address.phone;
        }
    }
    @action updateAdress = (address: IAdresse) => {
        if (address.isMainInvoiceAddress) {
            this.franchiseur!.invoiceAddress2 = address.additionalAddress;
            this.franchiseur!.invoiceAddress1 = address.address;
            this.franchiseur!.invoiceCity = address.city;
            this.franchiseur!.invoiceCountry = address.country;
            this.franchiseur!.invoicePostalCode = address.postalCode;
            this.franchiseur!.invoiceCompany = address.companyName;
            this.franchiseur!.invoicePhone = address.phone;
        }
        if (address.isMainDeliveryAddress) {
            this.franchiseur!.deliveryAddress2 = address.additionalAddress;
            this.franchiseur!.deliveryAddress1 = address.address;
            this.franchiseur!.deliveryCity = address.city;
            this.franchiseur!.deliveryCountry = address.country;
            this.franchiseur!.deliveryPostalCode = address.postalCode;
            this.franchiseur!.deliveryCompany = address.companyName;
            this.franchiseur!.deliveryPhone = address.phone;
        }
    }
    @action setPage = (page: number) => {
        this.page = page;
    }

    //@computed get axiosParams() {
    //    const params = new URLSearchParams();
    //    params.append('pageNumber', this.pagingParams.pageNumber.toString());
    //    params.append('pageSize', this.pagingParams.pageSize.toString());
    //    return params;
    //}
    @observable editFranchiseurMode: boolean = false;

    @observable verificationUpdate(newFranchiseur: IFranchiseur, oldFranchiseur: IFranchiseur) {
        var duplication = true;
        if (newFranchiseur.socialName != oldFranchiseur.socialName) return false;
        if (newFranchiseur.companyName != oldFranchiseur.companyName) return false;
        if (newFranchiseur.deliveryFirstName != oldFranchiseur.deliveryFirstName) return false;
        if (newFranchiseur.deliveryLastName != oldFranchiseur.deliveryLastName) return false;
        if (newFranchiseur.deliverySalutations != oldFranchiseur.deliverySalutations) return false;
        if (newFranchiseur.formeJuridique != oldFranchiseur.formeJuridique) return false;
        if (newFranchiseur.capitalValue != oldFranchiseur.capitalValue) return false;
        if (newFranchiseur.interventionZone != oldFranchiseur.interventionZone) return false;
        if (newFranchiseur.openingPackAmount != oldFranchiseur.openingPackAmount) return false;
        if (newFranchiseur.rcs != oldFranchiseur.rcs) return false;
        if (newFranchiseur.siretNumber != oldFranchiseur.siretNumber) return false;
        if (newFranchiseur.agreementNumber != oldFranchiseur.agreementNumber) return false;
        if (newFranchiseur.apeCode != oldFranchiseur.apeCode) return false;
        if (newFranchiseur.europeanTvaNumber != oldFranchiseur.europeanTvaNumber) return false;
        if (newFranchiseur.website != oldFranchiseur.website) return false;

        //if (newFranchiseur.invoiceEmails != oldFranchiseur.invoiceEmails) return false;
        //if (newFranchiseur.invoicePhone != oldFranchiseur.invoicePhone) return false;
        //if (newFranchiseur.invoiceFax != oldFranchiseur.invoiceFax) return false;
        //if (newFranchiseur.invoiceAddress1 != oldFranchiseur.invoiceAddress1) return false;
        //if (newFranchiseur.invoicePostalCode != oldFranchiseur.invoicePostalCode) return false;
        //if (newFranchiseur.invoiceCity != oldFranchiseur.invoiceCity) return false;
        //if (newFranchiseur.invoiceAddress2 != oldFranchiseur.invoiceAddress2) return false;

        return (duplication);
    }

    @action setPredicate = (predicate: string, value: any) => {
        this.franchiseurs = [];
        this.predicate.clear();
        if (predicate !== 'searchFranch') {
            this.predicate.clear();
        }
        if (predicate !== 'all') {
            this.predicate.set(predicate, value);
        }
    }

    @action clearActivity = () => {
        this.franchiseur = null;
    };


    @action setActiveTab = async (activeIndex: string) => {
        runInAction(() => {
            this.activeTab = activeIndex;
        })
    }

    sortByCode(franchiseurs: IFranchiseur[]) {

        const sotedByCode = franchiseurs.sort((a, b) => parseInt(a.agencyName) - parseInt(b.agencyName));

        return Object.entries(
            sotedByCode.reduce(
                (franchiseurs, franchiseur) => {
                    const code = parseInt(franchiseur.agencyName);
                    franchiseurs[code] = franchiseurs[code]
                        ? [...franchiseurs[code], franchiseur]
                        : [franchiseur];
                    return franchiseurs;
                },
                {} as { [key: string]: IFranchiseur[] }
            )
        );
    }

    @action loadFranchiseurs = async () => {
        this.loadingFranchideurs = true;
        try {
            const franchiseurEnveloppe = await agent.franchiseurAgent.list(this.axiosParams);
            const { franchiseurs, franchiseurCount } = franchiseurEnveloppe;
            runInAction(() => {
                if (this.page == 0)
                    this.franchiseurs = [];
                franchiseurs.forEach((franchiseur) => {
                    this.franchiseurs.push(franchiseur);
                })
                this.franchiseursCount = franchiseurCount;
                this.loadingFranchideurs = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingFranchideurs = false;
            })
            console.log(e);
        }
    }

    @action changeButtonState = async (newState: boolean) => {
        this.editFranchiseurMode = newState;
    }

    @action loadFranchiseur = async (id: number) => {
        this.loadingFranchiseur = true;
        //let franchiseur = this.getFranchiseur(id);
        //if (franchiseur) {
        //    this.franchiseur = franchiseur;
        //    this.loadingFranchiseur = false;
        //    return toJS(franchiseur);
        //}
        //else {
        try {
            var franchiseur = await agent.franchiseurAgent.details(id);
            if (franchiseur) {
                runInAction(() => {
                    //franchiseur.dateCreation.toDateString() = franchiseur.dateCreation.toString().split('T')[0];
                    this.franchiseur = franchiseur;
                    this.loadingFranchiseur = false;
                })
            }
        } catch (e) {
            runInAction(() => {
                this.loadingFranchiseur = false;
                history.push('/franchiseurDashboard');
            })
        }
    }



    @action create = async (values: IFranchiseur) => {
        this.submitting = true;
        try {
            var franchiseur: any = await agent.franchiseurAgent.create(values);
            runInAction(() => {
                this.franchiseur = franchiseur;
                this.submitting = false;
                history.push(`/detailFranchiseur/${franchiseur.id}`)
                toast.success("ajout effectué avec succès");
            })
        } catch (e) {
            toast.error("Problème d'ajout de Franchiseur!");
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }

    @action editFranchiseurGestionCommercial = async (values: Partial<IFranchiseur>) => {
        this.submitting = true;
        try {
            await agent.franchiseurAgent.updateGestionCommercial(values);
            runInAction(() => {
                this.franchiseur = { ...this.franchiseur!, ...values };
                this.submitting = false;
                this.changeButtonState(false);
                toast.success("Franchiseur modifié avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
        }
    }

    @action editFranchiseurspecification = async (values: Partial<IFranchiseur>) => {
        this.submitting = true;
        try {
            await agent.franchiseurAgent.updateSpecification(values);
            runInAction(() => {
                this.franchiseur = { ...this.franchiseur!, ...values };
                this.submitting = false;
                this.changeButtonState(false);
                toast.success("Modification effectuée avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action editFranchiseurComptabilite = async (values: Partial<IFranchiseur>) => {
        this.submitting = true;
        try {
            await agent.franchiseurAgent.updateComptabilite(values);
            runInAction(() => {
                this.franchiseur = { ...this.franchiseur!, ...values };
                this.submitting = false;
                this.changeButtonState(false);
                toast.success("Franchiseur modifié avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action editFranchiseur = async (values: Partial<IFranchiseur>) => {
        this.submitting = true;
        try {
            await agent.franchiseurAgent.update(values);
            runInAction(() => {
                this.franchiseur = { ...this.franchiseur!, ...values };
                this.submitting = false;
                this.changeButtonState(false);
                toast.success("Modification effectuée avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }

    @action disable = async (id: number) => {
        this.deletingFranchiseur = true;
        try {
            await agent.franchiseurAgent.disable(id);
            runInAction(() => {
                this.deletingFranchiseur = false;
                this.franchiseur!.isActive = false;
                toast.success("Franchiseur désactivé avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème désactivation franchiseur!");
            runInAction(() => {
                this.deletingFranchiseur = false;
            })
        }
    }

    @action enable = async (id: number) => {
        this.deletingFranchiseur = true;
        try {
            await agent.franchiseurAgent.enable(id);
            runInAction(() => {
                this.deletingFranchiseur = false;
                this.franchiseur!.isActive = true;
                toast.success("Franchiseur activé avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème d'activation franchiseur!");
            runInAction(() => {
                this.deletingFranchiseur = false;
            })
        }
    };
    @action uploadPhoto = async (file: Blob, id: number) => {
        this.uploadingPhoto = true;
        try {
            const photo = await agent.franchiseurAgent.uploadPhoto(file, id);
            runInAction(() => {
                if (this.franchiseur) {
                    this.franchiseur.photos.push(photo);
                    this.franchiseur.uri = photo.url;
                }
                this.uploadingPhoto = false;
                toast.success("Chargement de photo effectué avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de mise à jour photo!");
            runInAction(() => {
                this.uploadingPhoto = false;
            })
        }
    }
    @action setMainPhoto = async (photo: IPhoto, id: number) => {
        this.loadingPhotoAction = true;
        try {
            await agent.franchiseurAgent.setMainPhoto(photo.id, id);
            runInAction(() => {
                this._baseStore.userStore.user!.image = photo.url;
                this.franchiseur!.photos.find(a => a.isMain)!.isMain = false;
                this.franchiseur!.photos.find(a => a.id === photo.id)!.isMain = true;
                this.franchiseur!.image = photo.url;
                this.loadingPhotoAction = false;
                toast.success("Photo affectée avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de mise à jour photo de franchiseur!");
            runInAction(() => {
                this.loadingPhotoAction = false;
            })
        }
    };
    @action deletePhoto = async (photo: IPhoto, id: number) => {
        this.loadingPhotoAction = true;
        try {
            await agent.franchiseurAgent.deletePhoto(photo.id, id);
            runInAction(() => {
                this.franchiseur!.photos = this.franchiseur!.photos.filter(a => a.id !== photo.id);
                if (this.franchiseur?.photos.length == 0)
                    this.franchiseur.uri = "/assets/img/no-logo.png";
                this.loadingPhotoAction = false;
                toast.success("Photo franchiseur supprimée avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Probléme de suppression!");
            runInAction(() => {
                this.loadingPhotoAction = false;
            })
        }
    }

    @action uploadFile = async (file: Blob, id: number) => {
        this.uploadingFile = true;
        try {
            const fichier = await agent.franchiseurAgent.uploadFichier(file, id);
            runInAction(() => {
                if (this.franchiseur) {
                    this.franchiseur.fichiers.push(fichier);
                }
                this.uploadingFile = false;
                toast.success("Chargement de fichier est effectué avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.uploadingFile = false;
            })
        }
    }

    @action deleteFile = async (file: IFichier, id: number) => {
        this.deletingFranchiseur = true;
        try {
            await agent.franchiseurAgent.deleteFichier(file.id, id);
            runInAction(() => {
                this.franchiseur!.fichiers = this.franchiseur!.fichiers.filter(a => a.id !== file.id);
                this.deletingFranchiseur = false;
                toast.success("Suppression de fichier est effectué avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de suppresion du fichier!");
            runInAction(() => {
                this.deletingFranchiseur = false;
            })
        }
    }

    loadCompanyOptions = async (inputValue: string | null) => {
        if (!inputValue || !inputValue.trim() || inputValue.length < 2) {
            return await agent.franchiseurAgent.listFranchiseur(null).then(r => r.data);
        } else
            return await agent.franchiseurAgent.listFranchiseur(inputValue).then(r => r.data);
    };
    loadFranchiseurOptions = async (inputValue: string | null) => {
        if (!inputValue || !inputValue.trim() || inputValue.length < 2) {
            return await agent.franchiseurAgent.getSearchByFranchiseurs(null).then(r => r.data);
        } else
            return await agent.franchiseurAgent.getSearchByFranchiseurs(inputValue).then(r => r.data);
    };

    getName = async (companyName: string, SocialName: string) => {
        var test = await agent.franchiseurAgent.getFranchiseurName(companyName, SocialName);
        return test;
    };

    @action ExportFranchisseur = async () => {
        this.exporting = true
        try {
            await agent.franchiseurAgent.exportExcel();
            runInAction(() => {
                toast.success("Les clients sont exportés avec succés");
                this.exporting = false;
            })
        } catch (e) {
            runInAction(() => {
                this.exporting = false;
            })
            toast.error("Problem d'éxportation des donnèes!");
        }
    }

    @action ExportCompanies = async () => {
        this.exportingAll = true
        try {
            await agent.franchiseurAgent.exportAllCompanies();
            runInAction(() => {
                toast.success("Les clients sont exportés avec succés");
                this.exportingAll = false;
            })
        } catch (e) {
            runInAction(() => {
                this.exportingAll = false;
            })
            toast.error("Problem d'éxportation des donnèes!");
        }
    }


    @action exportOpeningPack = async (id: number) => {
        this.loadingDownloadPack = true;
        try {
            const list = await agent.franchiseurAgent.exportOpeningPack(id);
            runInAction(() => {
                this.listPackAmount = list;
                toast.success("Les soldes des packs d'ouverture des clients ont été exportés avec succès");
                this.loadingDownloadPack = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingDownloadPack = false;
            })
            toast.error("Problem d'éxportation des donnèes!");
        }
    }

    @action exportCommission = async (obj: any) => {
        this.exporting = true;
        try {
            await agent.franchiseurAgent.exportCommission(obj);
            runInAction(() => {
                toast.success("Les commissions sont exportés avec succés");
                this.exporting = false;
            })
        } catch (e) {
            runInAction(() => {
                this.exporting = false;
            })
            toast.error("Problem d'éxportation des donnèes!");
        }
    }


    @action loadCompany = async (id: number) => {
        this.loadingFranchiseur = true;
      
        try {
            var Ccompany = await agent.franchiseurAgent.detailCompany(id);      
                runInAction(() => {
                    this.company = Ccompany;
                    this.loadingFranchiseur = false;
                })
        } catch (e) {
            runInAction(() => {
                this.loadingFranchiseur = false;
                history.push('/franchiseurDashboard');
            })
        }
    }

    @action uploadPersonalPhoto = async (file: any, id: number) => {
        this.uploadingPhoto = true;
        try {
            const photo = await agent.clientAgent.uploadPhoto(file, file.fileName, id);
            runInAction(() => {
                if (this.franchiseur) {
                    this.franchiseur.personalPhotos.push(photo);
                }
                this.uploadingPhoto = false;
                toast.success("Chargement de photo effectué avec succès");
            })
        } catch (e) {
            toast.error("Problème de mise à jour photo!");
            runInAction(() => {
                this.uploadingPhoto = false;
            })
        }
    }
  
    @action deletePersonalPhoto = async (photo: IPersonalPhoto, id: number) => {
        this.loadingPhotoAction = true;
        try {
            await agent.clientAgent.deletePhoto(photo.id, id);
            runInAction(() => {
                this.franchiseur!.personalPhotos = this.franchiseur!.personalPhotos.filter(a => a.id !== photo.id);
                this.loadingPhotoAction = false;
                toast.success("Photo supprimée avec succès");
            })
        } catch (e) {
            toast.error("Problème de suppresion de la photo!");
            runInAction(() => {
                this.loadingPhotoAction = false;
            })
        }
    }

    @action uploadPersonalPhotos = async (files: any, id: number) => {
        this.uploadingPhoto = true;
        try {
            var photos = await agent.franchiseurAgent.uploadPhotos(files, id);
            runInAction(() => {
                photos.map((photo) => {
                    this.companyPhotos.push(photo);

                })
                this.uploadingPhoto = false;
                toast.success("Les photos sont enregistrés avec succès, merci d'accéder aux clients pour vérifier!!!");
            })
        } catch (e) {
            toast.error("Problème de mise à jour photo!");
            runInAction(() => {
                this.uploadingPhoto = false;
            })
        }
    }


    @action loadCompaniesPhotos = async (id: number) => {
        this.loadingPhotos = true;
        try {
            const photos = await agent.franchiseurAgent.listPhotos(id);
            runInAction(() => {
                this.companyPhotos = photos;
                this.loadingPhotos = false;
                toast.success("Chargement de photo effectué avec succès");
            })
        } catch (e) {
            toast.error("Problème de mise à jour photo!");
            runInAction(() => {
                this.loadingPhotos = false;
            })
        }
    }
}