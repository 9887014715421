import { useContext, useState, useEffect } from 'react';
import { Tab, Header, Card, Image, Button, Grid, Label, Loader, Popup } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import PhotoUploadWidget from '../../../app/common/photoUpload/PhotoUploadWidget';
import PhotosUpload from '../../../app/common/photoUpload/PhotosUpload';
import { observer } from 'mobx-react-lite';
import NoResult from '../../../app/common/util/NoResult';

const CompanyPersonalPhoto = () => {
    const baseStore = useContext(BaseStoreContext)
    const { franchiseur, uploadPersonalPhoto, uploadPersonalPhotos, companyPhotos, loadingPhotos, loadCompaniesPhotos, uploadingPhoto, deletePersonalPhoto, loadingPhotoAction } = baseStore.franchiseurStore;

    const [addPhotoMode, setAddPhotoMode] = useState(false);
    const [addMultiPhoto, setAddMultiPhoto] = useState(false);
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const handleUploadImage = (photo: Blob, id = franchiseur!.id) => {
        uploadPersonalPhoto(photo, id).then(() => setAddPhotoMode(false));
    }

    const handleUploadImages = (photos: any[], id = franchiseur!.id) => {
        uploadPersonalPhotos(photos, id).then(() => setAddMultiPhoto(false));
    }

    useEffect(() => {
        loadCompaniesPhotos(franchiseur!.id);
    }, [])
    return (
        <Tab.Pane>
            <Grid>
                <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                    <Header floated='left' icon='image' content='Logo' />
                    <Button
                        onClick={() => { setAddPhotoMode(!addPhotoMode); setAddMultiPhoto(false); }}
                        floated='right'
                        basic
                        content={addPhotoMode ? 'Annuler' : 'Ajouter Photo pour tout le réseau'}
                    />
                    <Button
                        onClick={() => { setAddMultiPhoto(!addMultiPhoto); setAddPhotoMode(false); }}
                        floated='right'
                        basic
                        content={addMultiPhoto ? 'Annuler' : 'Ajouter photos par franchisé'}
                    />
                </Grid.Column>
                <Grid.Column width={16}>
                    {addPhotoMode ? (
                        <PhotoUploadWidget
                            uploadPhoto={handleUploadImage}
                            loading={uploadingPhoto}
                        />
                    ) : (
                        addMultiPhoto ? (
                            <PhotosUpload
                                uploadPhotos={handleUploadImages}
                                loading={uploadingPhoto}
                            />
                        ) : (
                            <>
                                <Label content="Photos accessibles par tout le réseau" style={{ marginTop: 20, background: "none" }} />
                                <Card.Group itemsPerRow={5}>
                                    {franchiseur &&
                                        franchiseur.personalPhotos.length == 0 ?
                                        (<NoResult name="Photo partagée" />)
                                        :
                                        (<>
                                            {franchiseur && franchiseur.personalPhotos.map(photo => (
                                                <Card key={photo.id}>
                                                    <Image src={photo.url} />
                                                    <Button.Group fluid widths={2}>
                                                        <Button
                                                            name={photo.id}
                                                            onClick={(e) => {
                                                                deletePersonalPhoto(photo, franchiseur!.id);
                                                                setDeleteTarget(e.currentTarget.name)
                                                            }}
                                                            loading={loadingPhotoAction && deleteTarget === photo.id.toString()}
                                                            basic
                                                            negative
                                                            icon='trash'
                                                        />
                                                    </Button.Group>
                                                </Card>
                                            ))}

                                        </>
                                        )}
                                </Card.Group>
                                <Label content="Photos accessibles par franchisé" style={{ marginTop: 20, background: "none" }} />
                                {loadingPhotos ?
                                    (<div><Loader active={loadingPhotos} style={{ position: "relative", marginTop: 50, color: "green" }} >Chargement des photos clients</Loader></div>) :
                                    (
                                        <>
                                            {companyPhotos &&
                                                <>
                                                    {companyPhotos.length == 0 ?
                                                        <NoResult name="Photo autorisée" />
                                                        : (
                                                            <>

                                                                <Card.Group itemsPerRow={5} style={{ marginTop: 0 }}>
                                                                    {companyPhotos.map(photo => (
                                                                        <Popup key={photo.id}
                                                                            trigger={
                                                                                <Card className='img-block-preview' style={{ height: 230 }} key={photo.id}>
                                                                                    <Image className='img-preview-Content' style={{ height: 200, width: 'auto', overflow: 'hidden', marginBottom: 10 }} src={photo.url} />
                                                                                    <div style={{ textAlign: 'center', fontSize: 'medium', fontWeight: 'bold', width: "100%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                                                                                        <label >{photo.agencyName!}</label>
                                                                                    </div>
                                                                                </Card>
                                                                            }
                                                                            content={photo.companyName}
                                                                        />
                                                                    ))}
                                                                </Card.Group>

                                                            </>
                                                        )
                                                    }
                                                </>
                                            }
                                        </>
                                    )}
                            </>
                        ))}
                </Grid.Column>
            </Grid>
        </Tab.Pane>
    );
};

export default observer(CompanyPersonalPhoto);
